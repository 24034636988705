

// 底部导航栏
const btData = [
  { id: 'njserve', title: '农机总览' },
  { id: 'zyserve', title: '作业服务' },
  { id: 'njdd', title: '农机调度' },
  { id: 'njwx', title: '农机维修' }
];
/**
 农机总览左侧基本信息
 * {
 * srcName：图片地址
 * data：数据
 * unit：单位
 * introduce：下方信息
 * }
*/

const stateData = [
  {
    id: 1,
    srcName: require('@/assets/njImgs/njCount.png'),
    data: null,
    unit: '台',
    introduce: '农机总量'
  },
  {
    id: 2,
    srcName: require('@/assets/njImgs/njs.png'),
    data: 514,
    unit: '名',
    introduce: '农机手'
  },
  {
    id: 3,
    srcName: require('@/assets/njImgs/ljzymj.png'),
    data: 65122,
    unit: '亩次',
    introduce: '累计作业亩次'
  },
  {
    id: 4,
    srcName: require('@/assets/njImgs/zycs.png'),
    data: 6120,
    unit: '次',
    introduce: '作业次数'
  }
];
/**
 农机总览中间信息
 * {
 * title：题头信息
 * data：数据
 * unit：单位
 * }
*/
const MidData = [
  {
    id: 1,
    title: '农机总量',
    data: 56,
    unit: '台'
  },
  {
    id: 2,
    title: '农机总动力',
    data: '4,213,432',
    unit: 'Kw'
  },
  {
    id: 3,
    title: '农机原值',
    data: '4,213,432',
    unit: '元'
  },
  {
    id: 4,
    title: '购机补贴',
    data: '4,213,432',
    unit: '元'
  }
];
const MidDatafw = [
  {
    id: 1,
    title: '耕整作业',
    data: '6,542,541',
    unit: '亩次'
  },
  {
    id: 2,
    title: '机插作业',
    data: '5,662,451',
    unit: '亩次'
  },
  {
    id: 3,
    title: '机收作业',
    data: '4,123,410',
    unit: '亩次'
  },
  {
    id: 4,
    title: '植保作业',
    data: '224,512,12',
    unit: '亩次'
  }
];
/**
 农机总览右侧信息
 * {
    id：索引
 * title：模块底部信息
 * data：数据
 * logoSrc:logo地址
 * }
*/
const njstate = [
  {
    id: 1,
    title: '正常',
    data: 56,
    logoSrc: require('@/assets/njImgs/blueLogo.png')
  },
  {
    id: 2,
    title: '损坏',
    data: 33,
    logoSrc: require('@/assets/njImgs/orangeLogo.png')
  },
  {
    id: 3,
    title: '报废',
    data: 4,
    logoSrc: require('@/assets/njImgs/whriteLogo.png')
  },
  {
    id: 4,
    title: '忙碌',
    data: 55,
    logoSrc: require('@/assets/njImgs/greenLogo.png')
  },
  {
    id: 5,
    title: '待作业',
    data: 324,
    logoSrc: require('@/assets/njImgs/qblueLogo.png')
  },
  {
    id: 6,
    title: '近三年新增',
    data: 3123,
    logoSrc: require('@/assets/njImgs/purpleLogo.png')
  }
];
// 农机总览左下角
const propName = [
  '耕整机械',
  '机插机械',
  '收割机械',
  '植保机械',
  '烘干机械',
  '加工机械'
];
const propData = [ '60', '96', '86', '32', '45', '98' ];
const bdName = [ '耕整机械', '机插机械', '收割机械', '植保机械' ];
const bdData = [ '60', '96', '86', '32' ];
const gjbtData = [ '301241', '242351', '495214', '424541' ];
const njPieData = [
  { value: 15, name: '耕种', count: 50 },
  { value: 20, name: '机插', count: 50 },
  { value: 33, name: '收割', count: 50 },
  { value: 8, name: '植保', count: 50 }
];
export {
  btData,
  stateData,
  njstate,
  MidData,
  propName,
  propData,
  bdName,
  bdData,
  gjbtData,
  njPieData,
  MidDatafw
};
